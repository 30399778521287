import React from 'react';
import Radium from 'radium';
import Page from '../Page';
import Content from '../../components/Content';
import splashImage from '../../resources/splash/pbv2.jpg';


function DirectoryList() {
  return (
    <Page title={'Members Directory'} image={splashImage}>
      <Content title={'List of our members'}>(currently empty)</Content>
    </Page>
  );
}

export default Radium(DirectoryList);

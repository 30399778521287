/**
 * Returns a prettier date string than the default Date methods.
 *
 * @param {string} isoDate – Date to convert in ISO format.
 * @returns {string}
 */
function toPrettyDate(isoDate) {
  const date = new Date(isoDate);

  const cMonth = toCalendarMonth(date.getMonth());
  const cDay = date.getDate().toString().padStart(2, '0');
  const cYear = date.getFullYear();

  return `${cDay} ${cMonth} ${cYear}`;
}

/**
 * Returns the 3 letter calendar readable month.
 *
 * @param monthNumber - The month number returned from `Date().getMonth()`
 * @returns {string}
 */
function toCalendarMonth(monthNumber) {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return months[monthNumber]
}

export {
  toPrettyDate,
  toCalendarMonth
};
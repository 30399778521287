import React, { useEffect, useState } from 'react';
import Radium from 'radium';
import Page from '../Page';
import Content from '../../components/Content';
import someImage from '../../resources/splash/pbv3.jpg';
import EventCard from './EventCard';
import { size } from '../../utils/Theme';


function EventsList() {
  const CARD_DIMENSION = 200;

  const styles = {
    cardContainer: {
      display: 'flex',
      justifyContent: "center",
      flexFlow: 'row wrap',
      maxWidth: '100%',

      '@media (max-width: 1200px)': {
        margin: '0 auto',
      },

      '@media (max-width: 770px)': {
        width: 500,
      }
    }

  };

  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_HOST}/events`, { method: 'GET' }).then(async (res) => {

      const data = (await res.json())
        .filter(event => (new Date(event.date) >= new Date()))
        .sort((a, b) => new Date(a.date) > new Date(b.date) ? 1 : -1);

      setEvents(data);
    });
  }, []);

  return (
    <Page title={'Upcoming Events'} image={someImage}>
      <Content>
        {events.length === 0 ? (
          <p>No upcoming events to display!</p>
        ) : (
          <div style={styles.cardContainer}>
            {events.map((event, index) => {
              return (<EventCard {...event} key={index} />);
            })}
          </div>
        )}
      </Content>
    </Page>
  );
}

export default Radium(EventsList);

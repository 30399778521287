import React from 'react';
import Radium from 'radium';
import Page from '../Page';
import Content from '../../components/Content';
import splashImage from '../../resources/splash/pbv3.jpg';


function AboutPage() {
  const styles = {
    content: {
      textAlign: 'center',
    }
  }

  return (
    <Page title={'Our Story'} image={splashImage}>
      <Content title={'Our Guiding Principles'} style={styles.content}>
        <p>
          Puketāpapa Business Voice is an incorporated
          society, formed to:
        </p>

        <ol>
          <li>
            advance the commercial interests
            of business people and businesses in
            Puketāpapa
          </li>
          <li>
            promote the safety, security and welfare of
            the business community
          </li>
          <li>
            provide a forum for networking and
            collaboration of members
          </li>
          <li>
            support businesses on their journey
            towards zero carbon emissions and
            sustainability
          </li>
          <li>
            attract and retain business to drive
            employment and economic growth
          </li>
          <li>
            enhance Puketāpapa as a great place
            to do business, capitalising on its unique
            identity
          </li>
          <li>
            be the business voice for Puketāpapa
          </li>
          <li>
            advocate to central and local government
            for the improvement of infrastructure,
            services, amenity, security and cleaning
          </li>
        </ol>
      </Content>

      <Content title={'How do we work?'} style={styles.content}>
        <p>
          The association is an incorporated society, with membership open to businesses, business property owners and other agencies operating within the Puketāpapa area.
          The membership of the association elects a management committee, which runs the day-to-day operations of the association, guided by a long term strategy to advance the commercial interests of business people and businesses in Puketāpapa.
          <br />
          <br />
          The association is funded through membership fees, grants or contracts from government agencies, and commercial sponsorships.
        </p>
      </Content>

      <Content title={'Get in Touch'} style={styles.content}>
        <p>
          We can be reached at <a href={'mailto:puketapapabv@gmail.com'}>puketapapabv@gmail.com</a>.
        </p>
      </Content>
    </Page>
  );
}

export default Radium(AboutPage);

import React, { useState } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';

import HomePage from './pages/HomePage';
import EventsList from './events/EventsList';
import EventPage from './events/EventPage';
import AboutPage from './pages/AboutPage';
import NavMenuItem from '../components/navigation/NavMenuItem';
import NavMenu from '../components/navigation/NavMenu';
import DirectoryList from './member-directory/DirectoryList';


export default function MainRouter() {
  const [active, setActive] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuStateChange = (menuOpen) => {
    setMenuOpen(menuOpen);
  };

  const handleClick = (link) => {
    window.location = link;
    const parentLink = '/' + link.split('/')[1];
    setActive(parentLink);
    setMenuOpen(false);
  };

  const onLoad = (link) => {
    const parentLink = '/' + link.split('/')[1];
    setActive(parentLink);
  };

  return (
    <BrowserRouter>
      <NavMenu onLoad={onLoad} menuOpen={menuOpen} handleMenuStateChange={handleMenuStateChange}>
        <NavMenuItem displayText={'Home'} link={'/'} activeLink={active} handleClick={handleClick} />
        <NavMenuItem displayText={'Upcoming Events'} link={'/events'} activeLink={active} handleClick={handleClick} />
        {/*<NavMenuItem displayText={'Business Directory'} link={'/directory'} activeLink={active}*/}
        {/*             handleClick={handleClick} />*/}
        <NavMenuItem displayText={'About'} link={'/about'} activeLink={active} handleClick={handleClick} />
      </NavMenu>

      <Switch>
        {/* Static display pages */}
        <Route exact path={'/'} component={HomePage} />
        <Route exact path={'/about'} component={AboutPage} />

        {/* Events */}
        <Route exact path={'/events'} component={EventsList} />
        <Route exact path={'/events/:slug'} component={EventPage} />

        {/* Members */}
        <Route exact path={'/directory'} component={DirectoryList} />
        <Route exact path={'/directory/:slug'} />

        <Route path={'/'} component={HomePage} />
      </Switch>
    </BrowserRouter>
  );
}

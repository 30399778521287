import React from 'react';
import styled from 'styled-components';
import { colour } from '../utils/Theme';

import lbLogo from '../resources/lb_logo.png';

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  position: relative;
  width: 100vw;
  background-color: ${colour.orange};
  margin: 0;
  padding: 0;
  height: 180px;
`

const SMContainer = styled.div`
  display: grid;
  flex-grow: 1000;
  width: 400px;
  max-width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  
  p {
    color: white;
    grid-row: 1;
  }
`;

const SMLinks = styled.ul`
  grid-row: 2;
  list-style: none;
  padding: 0;
  
  li {
    display: inline-block;
  }
  
  a {
    margin: 0 20px 0 0;
    font-size: 40px;
    color: white;
  }
  
  
`;

const LBContainer = styled.div`
  flex-grow: 1;
  background: white;

  padding: 20px;
  height: 180px;
  box-sizing: border-box;
  object-fit: contain;
  
  p {
    margin: 10px auto 0 auto;
    max-width: 250px;
    font-size: 0.9rem;
    padding: 0;
    letter-spacing: 0.5px;
    font-style: italic;
    color: #434343;
  }

  img {
    display: block;
    text-align: center;
    margin: 0 auto;
    height: 80px;
    padding: 0 0 15px 0;
  }
`

export default function Footer(props) {
  return (
    <Container>
      <SMContainer>
        <p>Follow us on social media</p>
        <SMLinks>
          <li><a href={'https://www.facebook.com/PuketapapaBusinessVoice'} target="_blank">
            <i className="fab fa-facebook-square"/>
          </a></li>
          <li><a href={'https://www.linkedin.com/company/puket%C4%81papa-business-voice/'} target="_blank">
            <i className="fab fa-linkedin" />
          </a></li>
        </SMLinks>
      </SMContainer>

      <LBContainer>
        <p>
          This website has been generously funded by the Puketapapa Local Board
        </p>
        <img src={lbLogo} alt={"Puketapapa local board"} />
      </LBContainer>
    </Container>
  );
}

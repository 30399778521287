const colour = {
  red: 'maroon',
  orange: 'rgb(227, 95, 53)',
  orangeLight: 'gold',

  white: '#ffffff',
  offWhite: 'whitesmoke',

  navBackground: 'rgb(227, 95, 53)',
  navText: 'white',
  navHighlight: 'black',

  lightGrey: 'rgb(229,226,221)',
  grey: '#ddd',
  darkGrey: '#555',
};

const font = {
  serif: 'Piazzolla, serif',
  script: '"Shippori Mincho", serif',
  default: '"Neue Haas Pro", sans-serif',
};

const size = {
  sml: 550,
  med: 850,
  lg: 1300,
};

export {
  font,
  colour,
  size
};

import React from "react";
import Radium from "radium";
import { toCalendarMonth } from "../../utils/DateTimeUtils";
import { colour } from "../../utils/Theme";

/**
 * @param {Date} date
 * @param {Object} style
 */
function DateDisplay({ date, style = {} }) {
  const styles = {
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      backgroundColor: colour.orange,      
      color: 'white',
      borderRadius: "0 0 10px 0",
      padding: '10px 20px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px'
    },

    day: {
      textAlign: "center",
      color: colour.white,
      fontWeight: 700,
      fontSize: "26pt",
      padding: 0,
      margin: 0
    },

    month: {
      lineHeight: "1.2rem",
      margin: 0,
      padding: 0,
    }
  };

  if (date.getDate()) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = toCalendarMonth(date.getMonth());
    const year = date.getFullYear();

    return (
      <div style={{ ...styles.container, ...style }}>
        <p style={styles.day}>{day}</p>
        <div>
          <p style={styles.month}>{month}<br />{year}</p>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default Radium(DateDisplay);

import React from 'react';
import Radium from 'radium';
import { colour } from '../utils/Theme';
import Footer from '../components/Footer';


function Page({ title, image = null, children }) {
  const HEADER_HEIGHT = 300;

  
  const styles = {
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      height: '100vh',
    },

    page: {
      flexGrow: 1,
      backgroundColor: "rgb(250, 248, 245)",
    },    

    header: {
      minHeight: HEADER_HEIGHT,
      position: 'relative',
      height: HEADER_HEIGHT,
      background: `${colour.orangeLight} url(${image}) no-repeat center`,
      backgroundSize: 'cover',
      overflow: 'hidden'
    },

    title: {
      position: 'absolute',
      bottom: 50,
      left: 50,
      fontSize: 40,
      color: colour.white,
    },

    waveContainer: {
      height: HEADER_HEIGHT,
      minWidth: 1000,
      width: '100vw',
      position: 'absolute',
      bottom: '0',
    },

    waveSvg: {
      transformOrigin: 'bottom',
      transform: 'scale(1, 0.8)',
      position: 'absolute',
      bottom: '0',
      width: '100%',
      fill: colour.orange,
    }
  };

  return (
    <div className='container' id='page-wrap' style={styles.container}>
      <div style={styles.header}>
        <div style={styles.waveContainer}>
          <svg style={styles.waveSvg} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
            <path
              d='M0,96L60,90.7C120,85,240,75,360,101.3C480,128,600,192,720,234.7C840,277,960,299,1080,266.7C1200,235,1320,149,1380,106.7L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z' />
          </svg>
        </div>
        <h2 style={styles.title}>{title}</h2>
      </div>

      <div style={styles.page}>
        {children}
      </div>

      <Footer />
    </div>
  );
}

export default Radium(Page);

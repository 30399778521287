import React from "react";
import Radium from "radium";
import "@fortawesome/fontawesome-free/js/all.min";
import { colour, font, size } from "../../utils/Theme";
import splashImage from "../../resources/splash/pbv1.jpg";

import Content from "../../components/Content";
import Footer from "../../components/Footer";

import map from "../../resources/pbv-map.jpeg";
import qrCodeSignup from "../../resources/membership-qr.jpg";
import logo from "../../resources/logo-std.png";
import nwLogo from "../../resources/sponsor/n_w_logo.png";

import styled, { keyframes } from "styled-components";

const fadeKeyframeStyled = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const SplashContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(${splashImage}) center;
  background-size: cover;

  .splash-content {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%, 0);

    text-align: center;

    img {
      width: 350px;

      animation: ${fadeKeyframeStyled} 0.5s linear 0.2s 1;
      animation-fill-mode: backwards;

      @media screen and (max-width: ${size.sml}px) {
        width: 280px;
      }
    }

    h4 {
      font-family: "Neue Haas Pro", sans-serif;
      font-weight: 400;
      filter: drop-shadow(0 2px 5px black);
    }
  }
`;

const WhoWeAreContainer = styled.div`
  padding: 25px 0;
  text-align: center;
  position: relative;
  width: 100vw;
  background-color: ${colour.orange};

  h1 {
    font-size: 2rem;
    padding-top: 10px;
    text-align: center;
    color: white;
  }

  img {
    padding: 0 50px;
    height: 150px;
    filter: grayscale(1) brightness(1000);
  }
`;

const Img = styled.img`
  max-width: 100%;
  width: 500px;
  margin: 0 auto;

  display: block;
`;

const QrCodeBox = styled.div`
    display: flex;
    justify-content: center;

  p {
    vertical-align: middle;
    display: inline-block;
    width: 500px;
    max-width: 100%;
    box-sizing: border-box;
    padding-right: 50px;
  }
`;

const QrCode = styled.img`
  vertical-align: middle;
  display: inline-block;
  position: relative;
  max-width: 100%;
  width: 250px;
`;

const LogoImg = styled.img`
  display: inline-block;
  width: 200px;
  max-width: 100%;
  vertical-align: middle;
  padding-right: 30px;
`;

const ContactContainer = styled.div`
  width: 630px;
  max-width: 100%;
  margin: 40px auto;
  p {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    width: 400px;
  }
`;

function HomePage() {
  const fadeKeyframe = Radium.keyframes({
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  });

  const styles = {
    splashSubtitle: {
      marginTop: 15,
      color: colour.white,
      fontSize: 20,

      animation: "q 0.5s linear 0.5s 1",
      animationFillMode: "backwards",
      animationName: fadeKeyframe,
    },

    downCaretContainer: {
      position: "absolute",
      bottom: 50,
      left: 0,
      right: 0,
      margin: "0 auto",
      textAlign: "center",

      animation: "q 0.5s linear 2s 1",
      animationFillMode: "backwards",
      animationName: fadeKeyframe,
    },

    downCaret: {
      color: colour.white,
      fontSize: 40,

      transitionDuration: "0.2s",

      ":hover": {
        transitionDuration: "0.2s",
        cursor: "pointer",
        filter: "drop-shadow(0 5px 2px #00000055)",
      },
    },
  };

  const handleScrollDown = (e) => {
    window.scroll(0, window.innerHeight);
  };

  return (
    <div className="container" id="page-wrap">
      <SplashContainer>
        <div style={styles.splashContent} className="splash-content">
          <img src={logo} alt={"pbv logo"} />
          <h4 style={styles.splashSubtitle}>A head for business. A heart for Puketāpapa.</h4>
        </div>

        <div style={styles.downCaretContainer}>
          <a style={styles.downCaret} onClick={handleScrollDown}>
            <i className="fas fa-chevron-down" />
          </a>
        </div>
      </SplashContainer>
      <Content title={"Who We Are"}>
        <p>
          Puketāpapa Business Voice is an association for anybody who is involved with the business community in the
          Puketāpapa area.
        </p>
        <p>
          The Puketāpapa area includes the following suburbs of Lynfield, White Swan Road, Stoddard Road, Sandringham
          Road South, Mt Roskill North, Mt Roskill South, Three Kings, Royal Oak and Hillsborough.
        </p>

        <Img src={map} alt={"Map of Puketapapa"} />
      </Content>
      <Content title={"Join the Business Voice"}>
        <QrCodeBox>
          <p>
            Membership is open to all those involved with the business community in the Puketāpapa area. You can join by
            scanning the QR code to the right on your mobile device, or clicking{" "}
            <a
              href={
                "https://docs.google.com/forms/d/e/1FAIpQLSfv_6yPmD-U0hqOAflHf1_0L46UHCd62qWFfIP5l92qe5pueA/viewform"
              }
            >
              here
            </a>{" "}
            to be redirected to the sign up form.
          </p>
          <QrCode src={qrCodeSignup} alt={"QR Code signup"} />
        </QrCodeBox>
      </Content>
      <Content title={"Get In Touch"}>
        <ContactContainer>
          <LogoImg src={logo} alt={"Logo"} />
          <p>
            If you need a hand with your business related concerns, or would like to talk to someone on the team, get in
            touch with <a href={"mailto:puketapapabv@gmail.com"}>puketapapabv@gmail.com</a>
          </p>
        </ContactContainer>
      </Content>

      <Footer />
    </div>
  );
}

export default Radium(HomePage);

import React from 'react';
import Radium from 'radium';
import { toPrettyDate } from '../../utils/DateTimeUtils';
import { Link } from 'react-router-dom';
import { colour } from '../../utils/Theme';


const RLink = Radium(Link);

function EventCard({ name, tagline, date, photo, slug }) {
  const CARD_DIMENSION = 200;

  const styles = {
    eventCard: {
      display: 'flex',
      flexFlow: 'row wrap',
      position: 'relative',
      boxSizing: 'border-box',

      maxWidth: '100%',
      margin: '0 0 20px 0',

      backgroundColor: colour.offWhite,

      '@media (max-width: 770px)': {
        width: 500,
      }
    },

    cardContent: {
      padding: '20px 25px',
      width: 380,
    },

    date: {
      fontStyle: 'italic',
    },

    timeIndicator: {
      color: colour.orange,
    },


    moreLink: {
      textDecoration: 'none',
      color: colour.orange,
      fontStyle: 'italic',
    },

    cardImageContainer: {
      flexGrow: 1,
      height: CARD_DIMENSION,
      overflow: 'hidden',
      top: 0,
      left: 410,

      backgroundColor: colour.lightGrey,
    },

    cardImage: {
      objectFit: 'cover',
      objectPosition: 'center',
      height: CARD_DIMENSION,
      width: CARD_DIMENSION + 100,

      '@media (max-width: 770px)': {
        width: '100%',
      }
    }
  };

  const MILLIS_IN_DAY = 1000 * 24 * 60 * 60;
  const timeToEvent = Math.round((new Date(date) - Date.now()) / MILLIS_IN_DAY)

  return (
    <div style={styles.eventCard}>
      <div style={styles.cardContent}>
        <h3>{name}</h3>
        <p style={styles.date}>
          {toPrettyDate(date)} –
          <span style={styles.timeIndicator}> in {timeToEvent} day{timeToEvent === 1 ? "" : "s"} </span>
        </p>
        <p>{tagline}</p>
        <RLink style={styles.moreLink} onClick={() => (window.location = `/events/${slug}`)}>...more</RLink>
      </div>
      <div style={styles.cardImageContainer}>
        <img style={styles.cardImage} src={`${process.env.REACT_APP_API_HOST}${photo.url}`} alt='image' />
      </div>
    </div>
  );
}

export default Radium(EventCard);

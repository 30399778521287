import React, { useEffect, useState } from 'react';
import Radium from 'radium';
import Contact from '../../components/content-types/Contact';
import ReactMarkdown from 'react-markdown';
import Page from '../Page';
import Content from '../../components/Content';
import DateDisplay from '../../components/content-types/DateDisplay';
import splashImg from '../../resources/splash/pbv1.jpg';

import styled from 'styled-components';
import { colour } from '../../utils/Theme';

const Tagline = styled.p`
  font-style: italic;
  color: ${colour.orange};
`

function EventPage(props) {
  const styles = {
    descContainer: {
      display: 'inline-block',
      flexGrow: 1,
    },

    detailsContainer: {
      flexGrow: 1,
      display: "flex",
      flexFlow: "row nowrap",
    },

    imageContainer: {
      display: 'block',
      minWidth: 300,
      width: '38.5%',
      flexGrow: 1,

      textAlign: 'right'
    },

    img: {
      width: '100%'
    },

    detail: {
      display: 'flex',
      flexFlow: 'row wrap',
      marginBottom: 20,
    },

    date: {
      marginRight: 20,
    },

    bodyContainer: {
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: '20px'
    }
  }

  const querySlug = props.match.params.slug;

  const [event, setEvent] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  // Load the current event
  useEffect(() => {
    if (querySlug) {
      fetch(`${process.env.REACT_APP_API_HOST}/events?slug=${querySlug}`, { method: 'GET' }).then(async (res) => {

        const data = (await res.json())[0];
        setEvent(data);
        setIsLoading(false);
      });
    }
  }, [querySlug]);

  let imageDom = event.photo
    ? (<img style={styles.img} src={`${process.env.REACT_APP_API_HOST}${event.photo.url}`} alt='image' />)
    : null;


  return (
    <Page title={"Next up"} image={splashImg}>
      <Content title={event.name} style={styles.content}>
        {isLoading ? (
          <></>
        ) : (
          <div style={styles.bodyContainer}>
            <div style={styles.descContainer}>
              <Tagline>{event.tagline}</Tagline>

              <ReactMarkdown className='markdown'>{event.description}</ReactMarkdown>
            </div>
            <div style={styles.detailsContainer}>
              <Contact email={event.contact.email} name={event.contact.name} number={event.contact.number} />
              <DateDisplay date={new Date(event.date)} />
            </div>
            <div style={styles.imageContainer}>
              {imageDom}
            </div>


          </div>
        )}
      </Content>
    </Page>
  );
}

export default Radium(EventPage);

import { elastic as Menu } from 'react-burger-menu';
import Radium from 'radium';
import { colour } from '../../utils/Theme';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';


function NavMenu({ children, onLoad, menuOpen, handleMenuStateChange, ...props }) {
  const styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      left: '36px',
      top: '36px',

      filter: 'drop-shadow(0 2px 3px #00000044)',
    },

    bmBurgerBars: {
      background: colour.white,
    },

    bmCrossButton: {
      height: '24px',
      width: '24px'
    },

    bmCross: {
      background: colour.white,
    },

    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },

    bmMenu: {
      background: colour.navBackground,
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em'
    },

    bmMorphShape: {
      fill: colour.navBackground
    },

    bmItemList: {
      color: colour.navText,
      padding: '0.8em'
    },

    bmItem: {
      display: 'inline-block'
    },

    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  };

  const history = useHistory();

  useEffect(() => {
    onLoad(history.location.pathname);
  }, []);

  return (
    <Menu isOpen={menuOpen} left styles={styles} pageWrapId='page-wrap'
          onStateChange={(state) => handleMenuStateChange(state.menuOpen)} {...props}>
      {children}
    </Menu>
  );
}

export default Radium(NavMenu);

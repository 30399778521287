import React from 'react';
import 'normalize.css';

import '../styling/style.scss';
import '../resources/neue-haas-grotesk-display-pro-cufonfonts-webfont/font-nhd.css';
import MainRouter from './MainRouter';
import { StyleRoot } from 'radium';


export default function App() {
  return (
    <StyleRoot>
      <MainRouter />
    </StyleRoot>
  );
}

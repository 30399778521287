import React from 'react';
import Radium from 'radium';
import { Link } from 'react-router-dom';
import { colour } from '../../utils/Theme';


const RLink = Radium(Link);


/**
 *
 * @param {string} link - Relative link to page.
 * @param {string} displayText - Text to display inside link.
 * @param {string} activeLink - The current active link.
 * @param {function} handleClick - Function to call when a link is clicked
 */
function NavMenuItem({ link, displayText, activeLink, handleClick }) {
  const styles = {
    link: {
      textDecoration: 'none',
      color: 'inherit',
      display: 'block',
      margin: '20px 0',
    },

    link_active: {
      fontWeight: 'bold',
      color: colour.navHighlight
    }
  };

  return (
    <RLink onClick={() => handleClick(link)}
           style={{ ...styles.link, ...(activeLink === link && styles.link_active) }}>
      {displayText}
    </RLink>
  );
}

export default Radium(NavMenuItem);

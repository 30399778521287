import React from 'react';
import Radium from 'radium';
import { colour, font } from '../../utils/Theme';


function Contact({ name = null, email = null, number = null }) {
  const styles = {
    container: {
      position: 'relative',
      display: 'flex'
    },

    title: {
      marginTop: 15,
      marginBottom: 45,
      padding: 20,
      backgroundColor: colour.lightGrey,
    },

    textContainer: {
      padding: "20px 40px 20px 20px",
      backgroundColor: colour.grey,
    },

    text: {
      padding: 0,
      margin: 0,
    },

    name: {
      fontFamily: font.serif,
      fontWeight: 700,
    },

    icon: {
      fontSize: '0.8em',
    }
  }

  const nameDom = name ? (<p style={{...styles.text, ...styles.name}}>{name}</p>) : null;
  const emailDom = email ? (<p style={styles.text}><i style={styles.icon} className="far fa-envelope" /> {email}</p>) : null;
  const phoneDom = number ? (<p style={styles.text}><i style={styles.icon} className="fas fa-phone-square-alt" /> {number}</p>) : null;

  return (
    <div style={styles.container}>
      <div style={styles.textContainer}>
        {nameDom}
        {emailDom}
        {phoneDom}
      </div>
    </div>
  );
}

export default Radium(Contact);
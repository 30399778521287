import React from 'react';
import { size } from '../utils/Theme';
import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
`;

const ContentStyle = styled.div`
  position: relative;

  box-sizing: border-box;
  padding: 50px 25px;
  margin: 0 auto;
  max-width: 100%;
  width: ${size.lg}px;
  min-height: 400px;
  
  letter-spacing: 0.5px;
  
  li {
    margin-bottom: 10px;
  }
`

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 10px;
`

function Content({ title, children }) {

  return (
    <Container className={'container'}>
      <ContentStyle className={'content'}>
        <Title>{title}</Title>
        {children}
      </ContentStyle>
    </Container>
  );
}

export default Content;
